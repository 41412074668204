import React from 'react';
import AppRouter from './AppRouter';
import 'tailwindcss/tailwind.css';


function App() {
  return <AppRouter />;
}

export default App;
