
import madurai from './images/madurai.png';


export default function Example() {
  const cities = window.cities || []; // Access the cities array from window or use an empty array

  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            {/* <img
              className="h-11"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="museumofart.com"
            /> */}

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-11 w-11">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
            </svg>

            {/* <div className="hidden sm:mt-32 sm:flex lg:mt-16">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Anim aute id magna aliqua ad ad non deserunt sunt.{' '}
                <a href="#" className="whitespace-nowrap font-semibold text-indigo-600">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div> */}
            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
              Museum of Art
            </h1>
            <br></br>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              The Museum of Art is a resource for finding art museums, exhibitions, supplies and classes.  
            </p>
            <p className="mt-6 text-sm leading-8 text-gray-600">
              Please do not call us the Museum 'O Fart. 💨 💨 💨
            </p>
            <div className="mt-10 flex flex-wrap items-center gap-x-6">
              {cities.map((city, index) => (
                <a
                  key={index}
                  href={`/${city}`}
                  className="bg-white px-3.5 py-2.5 text-lg font-semibold text-black shadow-sm hover:bg-gray-50"
                  >
                  {city.toLocaleUpperCase()}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src={madurai}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}
