import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import City from './City';
import NotFound from './NotFound';
import  Classes from './Classes';
import  Museums from './Museums';

const AppRouter = () => {
  const citiesSet = new Set(); // Create a Set to store unique city names
  const routes = [];
  const postsByCity = {};

  window.pages.forEach((page) => {
    if (page.slug !== 'robots.txt') {
      const slugParts = page.slug.split('/');

      // const match = page.content.match(/{([\s\S]*?)}/);
      // const strippedString = match ? `{${match[1]}}` : page.content;

      // console.log(strippedString);

      const jsonStartIndex = page.content.indexOf('{');
      const jsonEndIndex = page.content.lastIndexOf('}');
      const jsonString = page.content.substring(jsonStartIndex, jsonEndIndex + 1);
      const pageMedia = page.media;

      const pageData = JSON.parse(jsonString);

      const cityName = slugParts[0];
      citiesSet.add(cityName);

      if (!postsByCity[cityName]) {
        postsByCity[cityName] = [];
      }

      if (page.page_type === 'post') {
        postsByCity[cityName].push(page);
      }

      const secondPart = slugParts[1];
      let routeElement;

      if (secondPart === 'museums') {
        routeElement = <Museums />;
      } else if (secondPart === 'classes') {
        routeElement = <Classes />;
      } else {
        routeElement = <City posts={postsByCity[cityName]} city={pageData} media={pageMedia} />;
      }

      routes.push(
        <Route key={page.slug} path={page.slug} element={routeElement} />
      );
    }
  });

  // const routes = window.pages.map((page) => {
    
  //   if (page.slug != "robots.txt")
  //   {
  //     const slugParts = page.slug.split('/'); // Split the slug by "/"
  //     const strippedString = page.content.replace(/^<div>(.*)<\/div>$/, '$1');
  //     const pageData = JSON.parse(strippedString);
  //     citiesSet.add(slugParts[0]); // Add the first part of the slug to the Set

  //     // Check the second part of the slug
  //     const secondPart = slugParts[1];
  //     let routeElement;
  //     if (secondPart === 'museums') {
  //       routeElement = <Museums />;
  //     } else if (secondPart === 'classes') {
  //       routeElement = <Classes />;
  //     } else {
  //       routeElement = <City posts={posts} city={pageData} />;
  //     }

  //     return (
  //       <Route
  //         key={page.slug}
  //         path={page.slug}
  //         element={routeElement}
  //       />
  //     );
  //   }
  // });

  const citiesArray = Array.from(citiesSet); // Convert the Set to an array

  // Assign the citiesArray to window.cities
  if (window) {
    window.cities = citiesArray;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {routes}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
